import MainnavSection from './MainnavSection';
import Element from './Element';

class Mainnav extends Element {
  constructor(element) {
    super(element);
    const secondaryElements = element.querySelectorAll('div[data-depth="1"]');
    const tertiaryElements = element.querySelectorAll('div[data-depth="2"]');
    const sections = [];

    secondaryElements.forEach((secondaryElement) => {
      const buttonElement = element.querySelector(`button[data-id="${secondaryElement.dataset.id}"]`);
      if (buttonElement) {
        sections.push(new MainnavSection(secondaryElement, buttonElement, this));
      }
    });
    tertiaryElements.forEach((tertiaryElement) => {
      const buttonElement = element.querySelector(`button[data-id="${tertiaryElement.dataset.id}"]`);
      if (buttonElement) {
        sections.push(new MainnavSection(tertiaryElement, buttonElement, this));
      }
    });

    this.sections = sections;
  }
}

export default Mainnav;
