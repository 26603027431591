class Timeline {
  constructor(element) {
    const itemElements = element.querySelectorAll('.timeline__item');
    const navElement = element.querySelector('.timeline__nav');
    const buttonElements = element.querySelectorAll('.timeline__nav button');
    const scrollContainerElement = element.querySelector('.timeline__scroll-container');

    this.element = element;
    this.navElement = navElement;
    this.scrollContainerElement = scrollContainerElement;
    this.itemElements = itemElements;
    this.buttonElements = buttonElements;

    [...buttonElements].forEach((buttonElement, index) => {
      buttonElement.addEventListener('click', () => {
        this.showItem(index);
      });
    });

    this.showItem([...itemElements].length - 1);
  }

  showItem(nth) {
    const {
      navElement,
      itemElements,
      buttonElements,
      scrollContainerElement,
    } = this;
    const scrollLeft = [...itemElements][nth].offsetLeft;
    scrollContainerElement.scrollTo({
      left: scrollLeft,
      behavior: 'smooth',
    });
    [...buttonElements].forEach((buttonElement, index) => {
      if (index === nth) {
        buttonElement.classList.add('-active');
        navElement.scrollTo({
          left: buttonElement.offsetLeft,
          behavior: 'smooth',
        });
      } else {
        buttonElement.classList.remove('-active');
      }
    });
  }
}

const timelineElements = document.querySelectorAll('.timeline');
[...timelineElements].forEach((timelineElement) => new Timeline(timelineElement));
