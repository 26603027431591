import Mapbox from './Mapbox';

const elements = document.querySelectorAll('.consent-external');

function loadExternalResource(element) {
  const { provider } = element.dataset;
  const formElement = element.querySelector('form');

  formElement.setAttribute('hidden', '');

  if (provider === 'Mapbox') {
    const mapElement = element.querySelector('.map');
    new Mapbox(mapElement);
  } else if (provider === 'YouTube' || provider === 'Vimeo') {
    const iframeElement = element.querySelector('iframe');
    if (iframeElement.dataset.src) {
      iframeElement.setAttribute('src', iframeElement.dataset.src);
      iframeElement.removeAttribute('data-src');
    }
  }
}

function onSubmit(event) {
  event.preventDefault();

  const formElement = event.target;
  const parentElement = formElement.closest('.consent-external');
  const { provider } = parentElement.dataset;

  loadExternalResource(parentElement);

  if (formElement.elements.remember.checked === true) {
    localStorage.setItem(`${provider}_consent`, 'true');

    // update other elements
    document.querySelectorAll(`.consent-external[data-provider="${provider}"]`).forEach((element) => {
      loadExternalResource(element);
    });
  }
}

elements.forEach((element) => {
  const { provider } = element.dataset;
  const formElement = element.querySelector('form');

  if (localStorage.getItem(`${provider}_consent`) === 'true') {
    loadExternalResource(element);
  } else {
    formElement.addEventListener('submit', onSubmit);
  }
});
