import PageThumb from './PageThumb';
import ApplicationsThumb from './ApplicationsThumb';
import Element from './Element';
import MaterialOverview from './MaterialOverview';
import MaterialTableItem from './MaterialTableItem';


class Search {
  constructor(element, mainnav, header) {
    const inputElements = element.querySelectorAll('input, select');

    const filterElement = element.querySelector('.filter');
    const resultsPagesElement = document.querySelector('#search-results-pages');
    const resultsApplicationsElement = document.querySelector('#search-results-applications');
    const resultsMaterialsElement = document.querySelector('#search-results-materials');
    const noResultsTextElement = element.querySelector('.search__no-results-text');
    const buttonShowMorePagesElement = resultsPagesElement.querySelector('.button-show-more');
    const buttonShowMoreApplicationsElement = resultsApplicationsElement.querySelector('.button-show-more');

    this.filterElement = filterElement;
    this.inputElements = inputElements;

    this.open = false;
    this.mainnav = mainnav;
    this.header = header;
    this.materialOverview = null;
    this.filterElementInstance = new Element(filterElement);
    this.resultsPagesElementInstance = new Element(resultsPagesElement);
    this.resultsApplicationsElementInstance = new Element(resultsApplicationsElement);
    this.resultsMaterialsElementInstance = new Element(resultsMaterialsElement);
    this.noResultsTextElementInstance = new Element(noResultsTextElement);
    this.buttonShowMorePagesElementInstance = new Element(buttonShowMorePagesElement);
    this.buttonShowMoreApplicationsElementInstance = new Element(buttonShowMoreApplicationsElement);

    this.filter = {};
    this.pageThumbs = [];
    this.applicationThumbs = [];
    this.showNoResultsTextTimeout = null;


    function createMaterialTrElement(data) {
      const trElement = document.createElement('tr');
      trElement.classList.add('material-table-item');
      trElement.dataset.forms = data.forms;
      trElement.dataset.uspCode = data.uspCode;
      trElement.dataset.title = data.title;
      trElement.dataset.modification = data.modification;
      trElement.dataset.brand = data.brand;
      trElement.dataset.particleSizes = data.particleSizes;
      trElement.dataset.materialNumbers = data.materialNumbers;
      trElement.dataset.carbonLoad = data.carbonLoad;
      trElement.dataset.poreSize = data.poreSize;
      trElement.dataset.text = data.text;
      // trElement.dataset.variants = data.variants;
      trElement.innerHTML = `
      <th>
        <a href="${data.url}">
          ${data.title}
        </a>
      </th>
      <td>
        ${data.particleSizeText}
      </td>
      <td>
        ${data.carbonLoad !== '' ? data.carbonLoad + ' %' : ''}
      </td>
      <td>
        ${data.poreSize !== '' ? data.poreSize + ' Å' : ''}
      </td>
      `;
      trElement.hidden = true;
      new MaterialTableItem(trElement);
      return trElement;
    }

    function createPageThumbsAndAppendToListElement(pages) {
      const listElement = resultsPagesElement.querySelector('.search-results__list');
      const pageThumbElements = [];
      pages.forEach((page) => {
        const pageThumb = new PageThumb(page);
        pageThumbElements.push(pageThumb.element);
        this.pageThumbs.push(pageThumb);
      });
      listElement.append(...pageThumbElements);
    }

    function createApplicationThumbsAndAppendToListElement(applications) {
      const listElement = resultsApplicationsElement.querySelector('.search-results__list');
      const applicationThumbElements = [];
      applications.forEach((application) => {
        const applicationThumb = new ApplicationsThumb(application);
        applicationThumbElements.push(applicationThumb.element);
        this.applicationThumbs.push(applicationThumb);
      });
      listElement.append(...applicationThumbElements);
    }

    function appendMaterialsToTbodyElement(materials) {
      const tbodyElement = resultsMaterialsElement.querySelector('.search-results__table tbody');
      materials.forEach((material) => {
        const trElement = createMaterialTrElement(material);
        tbodyElement.append(trElement);
      });
    }

    function initSearch() {
      if (!this.data) {
        this.getData().then(() => {
          const {
            materials,
            pages,
            applications,
          } = this.data;
          createPageThumbsAndAppendToListElement.bind(this)(pages);
          createApplicationThumbsAndAppendToListElement.bind(this)(applications);
          appendMaterialsToTbodyElement(materials);
          this.materialOverview = new MaterialOverview(resultsMaterialsElement, {
            updateHash: false,
            onFilterInputChange: false,
            updateMaterialThumbs: false,
          });
        });
      }
    }

    function onInputFocus(event) {
      if (event.target.closest('.search')) {
        initSearch.bind(this)();
        this.show();
      }
    }
    function onInputBlur() {
      const { filter } = this;
      setTimeout(() => {
        if (Object.keys(filter).length === 0 && !document.activeElement.closest('.search')) {
          this.hide().then(() => {
            mainnav.element.focus();
          });
        }
      }, 100);
    }
    function onInputChange(event) {
      this.updateFilter(event.target);
      this.updateResults();
    }
    function onButtonShowMorePagesClick() {
      resultsPagesElement.classList.add('-expanded');
    }
    function onButtonShowMoreApplicationsClick() {
      resultsApplicationsElement.classList.add('-expanded');
    }

    [...inputElements].forEach((inputElement) => {
      if (inputElement.type === 'text') {
        inputElement.addEventListener('input', onInputChange.bind(this));
      } else {
        inputElement.addEventListener('change', onInputChange.bind(this));
      }
      this.updateFilter(inputElement);
    });


    element.addEventListener('submit', (event) => {
      const firstLinkElement = [...element.querySelectorAll('a:not(.-hidden)')].find((linkElement) => linkElement.offsetWidth !== 0 && linkElement.offsetHeight !== 0);
      if (firstLinkElement) {
        firstLinkElement.focus();
      }
      event.preventDefault();
    });

    [...inputElements].forEach((inputElement) => inputElement.addEventListener('focus', onInputFocus.bind(this)));
    [...inputElements].forEach((inputElement) => inputElement.addEventListener('blur', onInputBlur.bind(this)));

    buttonShowMorePagesElement.addEventListener('click', onButtonShowMorePagesClick);
    buttonShowMoreApplicationsElement.addEventListener('click', onButtonShowMoreApplicationsClick);
  }

  updateFilter(inputElement) {
    const {
      materialOverview,
    } = this;
    if (materialOverview) {
      materialOverview.updateFilter(inputElement);
    }
    if (inputElement.value) {
      this.filter[inputElement.name] = inputElement.value;
    } else {
      delete this.filter[inputElement.name];
    }
  }

  getData() {
    const {
      data,
      loadingData,
    } = this;

    return new Promise((resolve, reject) => {
      if (!data && !loadingData) {
        this.loadingData = true;
        (async () => {
          this.data = await fetch('/search-data.json', {
            credentials: 'same-origin',
          }).then((response) => response.json());
          resolve();
        })();
      } else {
        reject();
      }
    });
  }


  updateResults() {
    const {
      materialOverview,
      resultsPagesElementInstance,
      resultsApplicationsElementInstance,
      buttonShowMorePagesElementInstance,
      buttonShowMoreApplicationsElementInstance,
      pageThumbs,
      applicationThumbs,
      filter,
      noResultsTextElementInstance,
      showNoResultsTextTimeout,
    } = this;

    const visiblePageThumbs = [];
    const visibleApplicationThumbs = [];
    const { query } = filter;

    if (showNoResultsTextTimeout) {
      clearTimeout(showNoResultsTextTimeout);
    }

    noResultsTextElementInstance.hide();
    this.resultsMaterialsElementInstance.show(true);

    if (materialOverview) {
      let hasSearchTermOrFilter = Object.keys(filter).length !== 0;
      if (Object.keys(filter).length === 1 && filter.query && filter.query.length < 2) {
        hasSearchTermOrFilter = false;
      }
      if (hasSearchTermOrFilter) {
        materialOverview.updateMaterialThumbs();

        let i = 0;
        pageThumbs.forEach((pageThumb) => {
          if (pageThumb.filter(query)) {
            i += 1;
            visiblePageThumbs.push(pageThumb);
            pageThumb.element.dataset.nth = i;
            pageThumb.show();
          } else {
            pageThumb.hide(true);
          }
        });

        let j = 0;
        applicationThumbs.forEach((applicationThumb) => {
          if (applicationThumb.filter(query)) {
            j += 1;
            visibleApplicationThumbs.push(applicationThumb);
            applicationThumb.element.dataset.nth = j;
            applicationThumb.show();
          } else {
            applicationThumb.hide(true);
          }
        });

        if (visiblePageThumbs.length > 0) {
          const searchResultsList = resultsPagesElementInstance.element.querySelector('.search-results__list');
          this.resultsPagesElementInstance.show(true);

          if (searchResultsList.offsetHeight > resultsPagesElementInstance.element.offsetHeight) {
            buttonShowMorePagesElementInstance.show();
          } else {
            buttonShowMorePagesElementInstance.hide();
          }
        }

        if (visibleApplicationThumbs.length > 0) {
          const searchResultsList = resultsApplicationsElementInstance.element.querySelector('.search-results__list');
          this.resultsApplicationsElementInstance.show(true);

          if (searchResultsList.offsetHeight > resultsApplicationsElementInstance.element.offsetHeight) {
            buttonShowMoreApplicationsElementInstance.show();
          } else {
            buttonShowMoreApplicationsElementInstance.hide();
          }
        }

        if (materialOverview.visibleMaterialThumbs.length > 0) {
          this.resultsMaterialsElementInstance.show(true);
        }

        if (materialOverview.visibleMaterialThumbs.length === 0) {
          this.resultsMaterialsElementInstance.hide(true);
        }

        // no results
        if (materialOverview.visibleMaterialThumbs.length === 0 && visiblePageThumbs.length === 0 && visibleApplicationThumbs.length === 0) {
          this.showNoResultsTextTimeout = setTimeout(() => {
            noResultsTextElementInstance.show();
          }, 500);
        }
      } else {
        // no search term or filter
        materialOverview.materialThumbs.forEach((materialThumb) => materialThumb.hide());
        pageThumbs.forEach((pageThumb) => pageThumb.hide(true));
      }

      if (visiblePageThumbs.length === 0) {
        this.resultsPagesElementInstance.hide(true);
      }

      if (visibleApplicationThumbs.length === 0) {
        this.resultsApplicationsElementInstance.hide(true);
      }

      if (query) {
        const results = materialOverview.visibleMaterialThumbs?.length ?? 0 + visiblePageThumbs?.length ?? 0;
        window._paq?.push(['trackSiteSearch', query, null, results]);
      }
    } else {
      // wait a second and run same method again, in the hope that by then materialOverview will have been created
      clearTimeout(this.updateResultsTimeout);
      this.updateResultsTimeout = setTimeout(() => {
        this.updateResults();
      }, 1000);
    }

    this.visiblePageThumbs = visiblePageThumbs;
  }

  show() {
    const {
      header,
      mainnav,
      visiblePageThumbs,
      filterElementInstance,
      resultsMaterialsElementInstance,
      resultsPagesElementInstance,
    } = this;
    this.open = true;
    mainnav.hide().then(() => {
      filterElementInstance.show();
      resultsMaterialsElementInstance.show();
      header.buttonElement.querySelector('b').innerText = 'Close search';
      if (visiblePageThumbs && visiblePageThumbs.length > 0) {
        resultsPagesElementInstance.show();
      }
    });
  }

  hide(instantly = false) {
    const {
      inputElements,
      filterElementInstance,
      resultsMaterialsElementInstance,
      resultsPagesElementInstance,
      noResultsTextElementInstance,
      header,
      mainnav,
    } = this;
    // only if no form element is focused
    if (![...inputElements].includes(document.activeElement)) {
      this.open = false;
      noResultsTextElementInstance.hide(instantly);
      filterElementInstance.hide(instantly);
      resultsPagesElementInstance.hide(instantly);
      header.buttonElement.querySelector('b').innerText = 'Close';
      return resultsMaterialsElementInstance.hide(instantly).then(() => mainnav.show(instantly));
    }
    return true;
  }
}

export default Search;
