/* eslint-disable no-param-reassign, no-plusplus */

import mapboxgl from 'mapbox-gl';

const EasingFunctions = {
  // no easing, no acceleration
  linear(t) { return t; },
  // accelerating from zero velocity
  easeInQuad(t) { return t * t; },
  // decelerating to zero velocity
  easeOutQuad(t) { return t * (2 - t); },
  // acceleration until halfway, then deceleration
  easeInOutQuad(t) { return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t; },
  // accelerating from zero velocity
  easeInCubic(t) { return t * t * t; },
  // decelerating to zero velocity
  easeOutCubic(t) { return (--t) * t * t + 1; },
  // acceleration until halfway, then deceleration
  easeInOutCubic(t) { return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; },
  // accelerating from zero velocity
  easeInQuart(t) { return t * t * t * t; },
  // decelerating to zero velocity
  easeOutQuart(t) { return 1 - (--t) * t * t * t; },
  // acceleration until halfway, then deceleration
  easeInOutQuart(t) { return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t; },
  // accelerating from zero velocity
  easeInQuint(t) { return t * t * t * t * t; },
  // decelerating to zero velocity
  easeOutQuint(t) { return 1 + (--t) * t * t * t * t; },
  // acceleration until halfway, then deceleration
  easeInOutQuint(t) { return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t; },
};

class Mapbox {
  constructor(element) {
    let isStartAnimationFinished = false;
    const lon = parseFloat(element.dataset.lon);
    const lat = parseFloat(element.dataset.lat);
    mapboxgl.accessToken = 'pk.eyJ1Ijoid3ctdG9iaWFzd29sZiIsImEiOiJja3BvNnB2bnkwaW9wMnZwNGxtcG13YnlxIn0.aQfZzaLDsGqosC7jc1jzpw';
    const startZoom = 19.9;
    const endZoom = 10;
    const startPitch = 60;
    const endPitch = 30;
    const destination = [lon, lat];
    const endCenter = [lon + 0.1, lat + 0.05];
    const map = new mapboxgl.Map({
      container: element,
      style: 'mapbox://styles/ww-tobiaswolf/ck1982og307sn1clcqiqm6u8v',
      center: destination,
      zoom: startZoom,
      pitch: startPitch,
      maxZoom: 19,
      minZoom: 3,
      bearing: -25,
    });

    function addMarker() {
      map.addLayer({
        id: 'points',
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [{
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: destination,
              },
            }],
          },
        },
        layout: {
          'icon-image': 'map-marker',
          'icon-size': 0.5,
        },
      });
    }

    map.on('load', () => {
      element.classList.add('-prevent-interaction');
      map.dragPan.disable();
      map.scrollZoom.disable();
      map.loadImage('/assets/images/map-marker@2x.png', (error, image) => {
        if (error) throw error;
        map.addImage('map-marker', image);
      });

      map.on('moveend', () => {
        if (!isStartAnimationFinished) {
          element.classList.remove('-prevent-interaction');
          addMarker();
          isStartAnimationFinished = true;
          map.dragPan.enable();
          map.scrollZoom.enable();
          map.touchZoomRotate.disableRotation();
        }
      });
      map.on('mouseenter', 'points', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'points', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('click', 'points', () => {
        if (map.getZoom() <= startZoom - (startZoom - endZoom) * 0.5) {
          map.flyTo({
            center: destination,
            zoom: startZoom,
            pitch: startPitch,
            bearing: -25,
            speed: 1,
            easing: EasingFunctions.easeInOutQuad,
          });
        } else {
          map.flyTo({
            zoom: endZoom,
            center: endCenter,
            bearing: 0,
            pitch: endPitch,
            speed: 1,
            easing: EasingFunctions.easeInOutQuad,
          });
        }
      });

      setTimeout(() => {
        element.classList.remove('is-loading');
        map.flyTo({
          zoom: endZoom,
          center: endCenter,
          bearing: 0,
          pitch: endPitch,
          speed: 0.4,
          easing: EasingFunctions.easeInOutQuad,
        });
      }, 200);
    });
  }
}

export default Mapbox;
