import Element from './Element';

class Popup extends Element {
  constructor(element) {
    super(element);

    const containerElement = element.querySelector('.popup__container');

    this.bodyElement = document.querySelector('body');
    this.containerElement = containerElement;

    function openOrClose() {
      let hashId = window.location.hash.substr(2);
      if (hashId.indexOf(',') !== -1) {
        hashId = hashId.substr(0, hashId.indexOf(','));
      }
      if (hashId === element.id) {
        this.show();
      } else {
        this.hide(false);
      }
    }

    window.addEventListener('hashchange', () => {
      openOrClose.bind(this)();
    });
    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.hide(true);
      }
    });
    element.addEventListener('click', (event) => {
      if (event.target.closest('.popup__container') !== containerElement) {
        this.hide();
      }
    });

    openOrClose.bind(this)();
  }

  hide(updateHash = true, instantly = false) {
    const {
      bodyElement,
    } = this;
    super.hide(instantly);
    bodyElement.classList.remove('-no-scroll');
    if (updateHash) {
      const { hash } = document.location;
      if (hash.indexOf(',') === -1) {
        document.location.hash = '#!';
      } else {
        document.location.hash = `#!${hash.substr(hash.indexOf(',') + 1)}`;
      }
    }
  }

  show() {
    const {
      bodyElement,
      element,
    } = this;
    super.show();
    bodyElement.classList.add('-no-scroll');
    element.focus();
  }
}

export default Popup;
