import Element from './Element';

class Reference extends Element {
  constructor(element) {
    super(element);
    this.data = element.dataset;
  }

  filterBySearchTerms(searchTerms) {
    const { title } = this.data;
    let containsXSearchTerms = 0;
    searchTerms.forEach((searchTerm) => {
      const lowerSearchTerm = searchTerm.toLowerCase();
      if (title.toLowerCase().includes(lowerSearchTerm)) {
        containsXSearchTerms += 1;
      }
    });
    return searchTerms.length === containsXSearchTerms;
  }

  filter(filter) {
    const { data } = this;
    let isFiltered = true;
    if (Object.keys(filter).length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filter) {
        if (filter[key] !== undefined && filter[key] !== null) {
          if (key === 'particleSize' && data.particleSizes) {
            if (!data.particleSizes.split(',').includes(filter[key])) {
              isFiltered = false;
            }
          } else if (key === 'length' && data.lengths) {
            if (!data.lengths.split(',').includes(filter[key])) {
              isFiltered = false;
            }
          } else if (key === 'innerDiameter' && data.innerDiameters) {
            if (!data.innerDiameters.split(',').includes(filter[key])) {
              isFiltered = false;
            }
          } else if (key === 'form' && data.forms) {
            if (!data.forms.split(',').includes(filter[key])) {
              isFiltered = false;
            }
          } else if (filter[key] !== data[key]) {
            isFiltered = false;
          }
        }
      }
    }
    return isFiltered;
  }
}

export default Reference;
