class FilterLabel {
  constructor(element) {
    const filterLabel = this;
    const selectElement = element.querySelector('select');
    this.element = element;
    this.selectElement = selectElement;
    this.name = selectElement.name;
    this.optionElements = selectElement.querySelectorAll('option');

    selectElement.addEventListener('focus', () => {
      element.classList.add('-active');
    });

    selectElement.addEventListener('blur', () => {
      filterLabel.updateStatus();
    });

    filterLabel.updateStatus();
  }

  updateStatus() {
    const {
      element,
      selectElement,
    } = this;
    if (selectElement.value === '') {
      element.classList.remove('-active');
    } else {
      element.classList.add('-active');
    }
  }

  setValue(value) {
    const {
      selectElement,
    } = this;
    selectElement.value = value;
  }

  hide() {
    const {
      element,
    } = this;
    element.hidden = true;
  }

  show() {
    const {
      element,
    } = this;
    element.hidden = false;
  }
}

export default FilterLabel;
