class Element {
  constructor(element) {
    this.element = element;

    function onTransitionend(event) {
      if (event.target === this.element) {
        this.element.hidden = element.classList.contains('-hidden');
        if (this.resolve && typeof this.resolve === 'function') {
          this.resolve();
          this.resolve = null;
        }
      }
    }

    element.addEventListener('transitionend', onTransitionend.bind(this));
  }

  show() {
    const {
      element,
    } = this;

    return new Promise((resolve) => {
      if (element.classList.contains('-hidden') || element.hidden) {
        this.resolve = resolve;
        element.hidden = false;
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            element.classList.remove('-hidden');
          });
        });
      } else {
        resolve();
      }
    });
  }

  hide(instantly = false) {
    const {
      element,
    } = this;

    return new Promise((resolve) => {
      if (instantly) {
        element.classList.add('-hidden');
        element.hidden = true;
        resolve();
      } else if (!element.classList.contains('-hidden')) {
        this.resolve = resolve;
        element.classList.add('-hidden');
      } else {
        resolve();
      }
    });
  }
}

export default Element;
