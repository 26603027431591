import './polyfills/append';
import './components/header';
import './components/material';
import './components/applications';
import './components/sliderHome';
import './components/timeline';
import './components/geoUriPolyfill';
import './components/consentExternal';
import './components/downloadRequest';
import MaterialOverview from './components/MaterialOverview';
import MaterialTableItem from './components/MaterialTableItem';

function detectIE() {
  const { userAgent } = window.navigator;
  if (userAgent.includes('MSIE ') || userAgent.includes('Trident/')) {
    return true;
  }

  return false;
}

const materialOverviewElement = document.querySelector('.material-overview');
if (materialOverviewElement) {
  // eslint-disable-next-line no-underscore-dangle
  document._materialOverview = new MaterialOverview(materialOverviewElement);
}

[...document.querySelectorAll('.material-table-item')].forEach((element) => new MaterialTableItem(element));

if (detectIE()) {
  document.querySelector('body').classList.add('-ie');
}
