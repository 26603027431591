import Element from './Element';

class MaterialImages {
  constructor(element) {
    this.element = element;
    this.images = [...element.querySelectorAll('.material-images__image')].map((imageElement) => new Element(imageElement));
    this.visibleImage = null;
  }

  show(form) {
    const { images } = this;
    let imageToShow = null;
    [...images].forEach((image) => {
      if (image.element.dataset.id === form) {
        imageToShow = image;
      }
    });
    if (this.visibleImage) {
      this.visibleImage.hide().then(() => {
        imageToShow.show();
      });
    } else if (imageToShow) {
      imageToShow.show();
    }
    this.visibleImage = imageToShow;
  }
}

export default MaterialImages;
