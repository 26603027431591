class SliderHome {
  constructor(element) {
    const itemElements = element.querySelectorAll('.slider-home-item');
    const buttonItemElements = element.querySelectorAll('.slider-home__nav button');
    let intervalNumber;
    let isAnimationAllowed = true;

    this.element = element;
    this.itemElements = itemElements;
    this.firstItemElement = [...itemElements].shift();
    this.lastItemElement = [...itemElements].pop();
    this.currentItemElement = this.firstItemElement;
    this.buttonItemElements = buttonItemElements;

    function createInterval() {
      if (!intervalNumber) {
        intervalNumber = setInterval(() => {
          if (isAnimationAllowed) {
            this.showNext();
          }
        }, 5000);
      }
    }

    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 39) {
        this.showNext();
      } else if (event.keyCode === 37) {
        this.showPrevious();
      }
    });

    [...buttonItemElements].forEach((buttonItemElement, index) => {
      buttonItemElement.addEventListener('click', () => {
        this.showNth(index);
      });
    });

    element.addEventListener('mouseenter', () => {
      intervalNumber = clearInterval(intervalNumber);
      isAnimationAllowed = false;
    });
    element.addEventListener('mouseleave', () => {
      isAnimationAllowed = true;
      if (!intervalNumber) {
        createInterval.bind(this)();
      }
    });
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        createInterval.bind(this)();
      } else {
        intervalNumber = clearInterval(intervalNumber);
      }
    });

    createInterval.bind(this)();
  }

  updateNav() {
    const {
      currentItemElement,
      itemElements,
      buttonItemElements,
    } = this;

    const activeIndex = [...itemElements].indexOf(currentItemElement);

    [...buttonItemElements].forEach((item, index) => {
      if (index === activeIndex) {
        item.classList.add('-active');
      } else {
        item.classList.remove('-active');
      }
    });
  }

  showNth(index) {
    const {
      currentItemElement,
      itemElements,
    } = this;

    const activeIndex = [...itemElements].indexOf(currentItemElement);
    const nextItemElement = [...itemElements][index] || currentItemElement;

    if (index > activeIndex) {
      nextItemElement.style.transitionDuration = '0s';
      nextItemElement.classList.remove('-previous');
      nextItemElement.classList.add('-next');

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          nextItemElement.style.transitionDuration = '';
          currentItemElement.classList.add('-previous');
          nextItemElement.classList.remove('-next');
        });
      });
    } else if (index < activeIndex) {
      nextItemElement.style.transitionDuration = '0s';
      nextItemElement.classList.remove('-next');
      nextItemElement.classList.add('-previous');

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          nextItemElement.style.transitionDuration = '';
          currentItemElement.classList.add('-next');
          nextItemElement.classList.remove('-previous');
        });
      });
    }

    this.currentItemElement = nextItemElement;
    this.updateNav();
  }

  showNext() {
    const {
      currentItemElement,
      firstItemElement,
    } = this;

    const nextElement = currentItemElement.nextElementSibling ? currentItemElement.nextElementSibling : firstItemElement;

    nextElement.style.transitionDuration = '0s';
    nextElement.classList.remove('-previous');
    nextElement.classList.add('-next');

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        nextElement.style.transitionDuration = '';
        currentItemElement.classList.add('-previous');
        nextElement.classList.remove('-next');
      });
    });

    this.currentItemElement = nextElement;
    this.updateNav();
  }

  showPrevious() {
    const {
      currentItemElement,
      lastItemElement,
    } = this;

    const previousElement = currentItemElement.previousElementSibling ? currentItemElement.previousElementSibling : lastItemElement;

    previousElement.style.transitionDuration = '0s';
    previousElement.classList.remove('-next');
    previousElement.classList.add('-previous');

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        previousElement.style.transitionDuration = '';
        currentItemElement.classList.add('-next');
        previousElement.classList.remove('-previous');
      });
    });

    this.currentItemElement = previousElement;
    this.updateNav();
  }
}

const element = document.querySelector('.slider-home');
if (element) {
  // eslint-disable-next-line no-new
  new SliderHome(element);
}
