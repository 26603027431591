import FilterLabel from './FilterLabel';
import MaterialThumb from './MaterialThumb';

class MaterialOverview {
  constructor(element, options = {}) {
    const productOverview = this;
    const filterElementId = element.dataset.filterId;
    const filterElement = document.getElementById(filterElementId);
    const filterLabelElements = filterElement.querySelectorAll('.filter__label');
    const filterInputElements = filterElement.querySelectorAll('.filter__label select, input[type="hidden"]');
    const materialThumbElements = element.querySelectorAll('.product-thumb, .material-table-item');
    const filterLabels = [];
    this.element = element;
    this.materialThumbs = [];
    this.filterElement = filterElement;
    this.filter = {};
    this.options = options;

    [...filterLabelElements].forEach((filterLabelElement) => {
      filterLabels.push(new FilterLabel(filterLabelElement));
    });
    [...materialThumbElements].forEach((materialThumbElement) => {
      this.materialThumbs.push(new MaterialThumb(materialThumbElement));
    });
    [...filterInputElements].forEach((filterInputElement) => {
      if (options.onFilterInputChange !== false) {
        filterInputElement.addEventListener('change', () => {
          this.updateFilter(filterInputElement);
          this.updateHash();
          this.updateMaterialThumbs();
        });
      }
      filterInputElement.addEventListener('focus', () => {
        if (filterInputElement.tagName === 'SELECT') {
          this.updateSelectElement(filterInputElement);
        }
      });
      this.updateFilter(filterInputElement);
    });

    function updateLabelsBasedOnHash(hash) {
      const hashArray = hash.split(',');
      hashArray.forEach((item) => {
        const itemNameValue = item.split('=');
        if (itemNameValue[0] && itemNameValue[1]) {
          const name = itemNameValue[0];
          const value = itemNameValue[1];
          filterLabels.forEach((label) => {
            if (label.name === name) {
              label.setValue(value);
              label.updateStatus();
              productOverview.updateFilter(label.selectElement);
            }
          });
        }
      });
    }

    const hash = window.location.hash.substr(2);
    if (hash) {
      if (this.constructor.name !== 'Search') {
        updateLabelsBasedOnHash(hash);
      }
    } else {
      this.updateHash();
    }
    if (options.updateMaterialThumbs !== false) {
      this.updateMaterialThumbs();
    }
  }

  updateSelectElement(selectElement) {
    const { materialThumbs, filter } = this;
    const { options, name } = selectElement;
    const filterExcludingSelectElement = JSON.parse(JSON.stringify(filter)); // copy object
    delete filterExcludingSelectElement[name];

    const filteredMaterialThumbs = materialThumbs.filter((materialThumb) => materialThumb.filter(filterExcludingSelectElement));

    function selectOptionIsAvailable(optionElement) {
      return filteredMaterialThumbs.filter((materialThumb) => {
        const { data } = materialThumb;
        const { value } = optionElement;
        if (name === 'particleSize' && data.particleSizes) {
          if (data.particleSizes.split(',').includes(value)) {
            return true;
          }
        } else if (name === 'form' && data.forms) {
          if (data.forms.split(',').includes(value)) {
            return true;
          }
          return false;
        } else if (name === 'uspCode' && data.forms) {
          if (data.uspCode.split(', ').includes(value)) {
            return true;
          }
          return false;
        }
        return data[name] === value;
      }).length > 0;
    }

    [...options].forEach((optionElement) => {
      if (optionElement.value !== '') {
        if (selectOptionIsAvailable(optionElement)) {
          optionElement.removeAttribute('disabled');
        } else {
          optionElement.setAttribute('disabled', '');
        }
      }
    });
  }

  updateFilter(inputElement) {
    if (inputElement.value) {
      this.filter[inputElement.name] = inputElement.value;
    } else {
      delete this.filter[inputElement.name];
    }
  }

  updateHash() {
    const { filter, options } = this;
    if (options.updateHash !== false) {
      const hashArray = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filter) {
        if (filter[key] && key !== 'form') {
          hashArray.push(`${key}=${filter[key]}`);
        }
      }
      if (hashArray.length > 0) {
        window.history.replaceState(null, document.title, `#!${hashArray.toString()}`);
      } else if (window.location.hash.length > 2) {
        window.history.replaceState(null, document.title, '#!');
      }
    }
  }

  updateMaterialThumbs() {
    const {
      filter,
      materialThumbs,
    } = this;
    const visibleMaterialThumbs = [];
    materialThumbs.forEach((materialThumb) => {
      materialThumb.updateLink(filter);
      if (materialThumb.filter(filter)) {
        materialThumb.show();
        visibleMaterialThumbs.push(materialThumb);
      } else {
        materialThumb.hide();
      }
    });
    this.visibleMaterialThumbs = visibleMaterialThumbs;
  }
}

export default MaterialOverview;
