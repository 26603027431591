import Element from './Element';

class ApplicationThumb extends Element {
  constructor(data) {
    const {
      url,
      title,
      material,
    } = data;
    const element = document.createElement('a');
    element.innerHTML = `
    <div class="application-thumb__title">${title}</div>
    <div class="application-thumb__variant">${material}</div>
    `;
    element.classList.add('application-thumb');
    element.setAttribute('href', url);
    // element.hidden = true;

    super(element);

    this.data = data;
    this.element = element;
  }

  filter(searchTerm) {
    if (searchTerm) {
      const {
        title,
        substances,
      } = this.data;
      const lowerSearchTerm = searchTerm.toLowerCase();
      const lowerCaseSubstances = substances.map((item) => item.toLowerCase());
      return title.toLowerCase().includes(lowerSearchTerm) || lowerCaseSubstances.join(', ').includes(lowerSearchTerm);
    }
    return false;
  }
}

export default ApplicationThumb;
