import countries from './countries';
import Popup from './Popup';

class DownloadRequest {
  constructor(element) {
    this.element = element;
    this.href = element.href;

    element.addEventListener('click', (event) => {
      event.preventDefault();
      this.openPopup();
    });
  }

  resetFormErrors() {
    const { formElement } = this;
    const { elements } = formElement;
    [...elements].forEach((itemElement) => {
      const fieldElement = itemElement.closest('.field');
      if (fieldElement) {
        fieldElement.querySelector('.field__error').innerText = '';
      }
    });
  }

  showFormErrors(errors) {
    const { formElement } = this;
    const { elements } = formElement;
    Object.keys(errors).forEach((errorKey) => {
      if (elements[errorKey]) {
        elements[errorKey].closest('.field').querySelector('.field__error').innerText = errors[errorKey];
      } else {
        console.error(errorKey, errors[errorKey]);
      }
    });
  }

  showSuccess() {
    const {
      href,
      formElement,
    } = this;
    const successElement = Object.assign(document.createElement('div'), {
      className: 'form__success-text',
      innerHTML: `
        Please click <a href="${href}" download>Download PDF</a> if the download does not start automatically.
      `,
    });

    const linkElement = successElement.querySelector('a');

    linkElement.click();

    formElement.classList.add('-success');

    formElement.querySelector('.button').remove();
    formElement.appendChild(successElement);
  }

  openPopup() {
    this.createPopup();
    this.popup.show();
  }

  createPopup() {
    const {
      href,
    } = this;

    function generateRandomString(length = 10) {
      const characters = 'abcdefghijklmnopqrstuvwxyz';
      const characterCount = characters.length;

      let randomString = '';
      const randomValues = new Uint32Array(length);
      crypto.getRandomValues(randomValues);

      for (let i = 0; i < length; i += 1) {
        randomString += characters.charAt(randomValues[i] % characterCount);
      }

      return randomString;
    }

    const popupElement = Object.assign(document.createElement('div'), {
      classList: 'popup -hidden',
      id: generateRandomString(),
      innerHTML: '<div class="popup__container"></div>',
    });

    document.body.appendChild(popupElement);

    this.popup = new Popup(popupElement);

    let countriesSelectElement = '<select name="country" required autocomplete="country">';
    countriesSelectElement += '<option></option>';
    Object.keys(countries).forEach((key) => {
      countriesSelectElement += `<option value="${key}">${countries[key]}</option>`;
    });
    countriesSelectElement += '</select>';

    const introText = '<h2>Before you download the brochure, please provide the following information:</h2>';

    const formHTML = `
      <form class="form" method="post" action="/api/request-download">
        <h3>Personal Details</h3>
        <input type="hidden" name="download" value='${href}'>
        <label class="field">
          <span class="field__label">Name <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="name">
        </label>
        <div class="visually-hidden field">
          <span class="field__label">Honigtopf <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="url" name="honigtopf" tabindex="-1">
        </div>
        <label class="field">
          <span class="field__label">Company <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="company">
        </label>
        <label class="field">
          <span class="field__label">Job Title <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="job_title">
        </label>
        <label class="field">
          <span class="field__label">Business Email Address <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="email" required name="email">
        </label>
        <label class="field">
          <span class="field__label">Phone Number</span>
          <span class="field__error"></span>
          <input type="tel" name="phone">
        </label>
        <label class="field">
          <span class="field__label">Street Address <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="street_address">
        </label>
        <label class="field -span-2">
          <span class="field__label">Postal Code / ZIP</span>
          <span class="field__error"></span>
          <input type="text" name="postal_code">
        </label>
        <label class="field -span-4">
          <span class="field__label">City <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="city">
        </label>
        <label class="field">
          <span class="field__label">Country <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          ${countriesSelectElement}
        </label>
        <label class="field -checkbox">
          <input type="checkbox" name="newsletter" checked>
          <span>
            <span class="field__label">I hereby confirm that I would like to receive marketing and promotional Email messages about Dr. Maisch HPLC products and services.</span>
            <span class="field__error"></span>
          </span>
        </label>
        <label class="field -checkbox">
          <input type="checkbox" name="legal">
          <span>
            <span class="field__label">I acknowledge that Dr. Maisch uses the information I provide to respond to my request and may use this information to provide me with other information they believe may be of interest in the future. Our privacy policy can be found <a href="https://dr-maisch.com/privacy-policy" target="_blank">here</a>. At any time, you may request to change your data protection preferences or remove your data by clicking <a href="https://dr-maisch.com/privacy-policy" target="_blank">here</a>.</span>
            <span class="field__error"></span>
          </span>
        </label>
        <button class="button">Download PDF</button>
      </form>
    `;

    this.popup.containerElement.innerHTML = introText + formHTML;

    this.formElement = this.popup.containerElement.querySelector('form');
    this.formElement.addEventListener('submit', (event) => {
      event.preventDefault();
      this.submitForm();
    });
  }

  submitForm() {
    const { formElement } = this;
    const formData = new FormData(formElement);
    formElement.classList.add('-loading');
    this.resetFormErrors();

    // Disable form elements
    Array.from(formElement).forEach((itemElement) => {
      Object.assign(itemElement, {
        disabled: true,
      });
    });

    fetch(formElement.action, {
      method: formElement.method,
      body: formData,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    }).then((data) => {
      if (data.success === true) {
        this.showSuccess();
      } else if (data.errors) {
        this.showFormErrors(data.errors);
      }
    }).catch((error) => console.error(error))
      .finally(() => {
        formElement.classList.remove('-loading');
        if (!formElement.classList.contains('-success')) {
          // Enable form elements
          Array.from(formElement).forEach((itemElement) => {
            Object.assign(itemElement, {
              disabled: false,
            });
          });
        }
      });
  }
}

document.querySelectorAll('a.button[href*=".pdf"]').forEach((element) => new DownloadRequest(element));
