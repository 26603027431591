import FilterLabel from './FilterLabel';

class Applications {
  constructor(element) {
    // elements
    const filterLabelElements = element.querySelectorAll('.filter__label');
    const inputElements = element.querySelectorAll('select, input');

    // fields
    this.applicationThumbElements = element.querySelectorAll('.application-thumb');
    this.filterLabels = [];
    this.filter = {
      substance: null,
    };

    // event listener
    const onFilterChange = (event) => {
      this.filter.substance = event.target.value;
      this.filterElements();
    };

    inputElements.forEach((inputElement) => {
      if (inputElement.name === 'substance') {
        inputElement.addEventListener('change', onFilterChange.bind(this));
      }
    });

    // init
    filterLabelElements.forEach((filterLabelElement) => {
      this.filterLabels.push(new FilterLabel(filterLabelElement));
    });
  }

  filterElements() {
    const { filter } = this;
    this.applicationThumbElements.forEach((applicationThumbElement) => {
      let isHidden = false;
      if (filter.substance) {
        if (!applicationThumbElement.dataset.substances.split(',').includes(filter.substance)) {
          isHidden = true;
        }
      }
      applicationThumbElement.hidden = isHidden;
    });
  }
}

const element = document.querySelector('.applications');
if (element) {
  // eslint-disable-next-line no-underscore-dangle
  document._applications = new Applications(element);
}
