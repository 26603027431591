class MaterialThumb {
  constructor(element) {
    const linkElement = element.querySelector('a');
    const imgElement = element.querySelector('img');

    this.data = element.dataset;
    this.element = element;
    this.linkElement = linkElement;
    this.imgElement = imgElement;
    this.href = linkElement.href;
    this.isImgLoaded = imgElement && imgElement.dataset.src === undefined;
    this.materialNumber1Regex = /(\w+\d+\.)/; // e.g. “r15.”
    this.materialNumber2Regex = /(\w+\d+\.\w+)/; // e.g. “r15.4e”
  }

  updateLink(filter) {
    const { linkElement, href, materialNumber1Regex } = this;
    const hashArray = [];
    const allowedKeys = ['form', 'particleSize', 'length', 'innerDiameter', 'weight'];
    const { query } = filter;

    if (query && materialNumber1Regex.exec(query) !== null) {
      hashArray.push(`materialNumber=${query}`);
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key in filter) {
      if (filter[key] && allowedKeys.includes(key)) {
        hashArray.push(`${key}=${filter[key]}`);
      }
    }
    if (hashArray.length > 0) {
      linkElement.href = `${href}#!productVariant[${hashArray.toString()}]`;
    }
  }

  filterBySearchTerms(searchTerms) {
    const { title, modification, text } = this.data;
    let containsXSearchTerms = 0;
    searchTerms.forEach((searchTerm) => {
      const lowerSearchTerm = searchTerm.toLowerCase();
      if (title.toLowerCase().includes(lowerSearchTerm)) {
        containsXSearchTerms += 1;
      }
      if (modification.toLowerCase().includes(lowerSearchTerm)) {
        containsXSearchTerms += 1;
      }
      if (text.toLowerCase().includes(lowerSearchTerm)) {
        containsXSearchTerms += 1;
      }
    });
    return searchTerms.length <= containsXSearchTerms;
  }

  filter(filter) {
    const { data, materialNumber1Regex, materialNumber2Regex } = this;
    let isFiltered = true;
    if (Object.keys(filter).length > 0) {
      const { query } = filter;

      if (query && materialNumber1Regex.exec(query) !== null) {
        if (materialNumber2Regex.exec(query) !== null) {
          const materialNumber2RegexWithoutSize = `${materialNumber2Regex.exec(query)[0]}`;
          isFiltered = data.materialNumbers.toLowerCase().includes(materialNumber2RegexWithoutSize);
        } else {
          const materialNumber1RegexWithoutSize = materialNumber1Regex.exec(query)[0];
          isFiltered = data.materialNumbers.split(',').filter((materialNumber) => materialNumber.startsWith(materialNumber1RegexWithoutSize)).length > 0;
        }
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in filter) {
          if (filter[key] !== undefined) {
            if (key === 'particleSize' && data.particleSizes) {
              if (!data.particleSizes.split(',').includes(filter[key])) {
                isFiltered = false;
              }
            } else if (key === 'form' && data.forms) {
              if (!data.forms.split(',').includes(filter[key])) {
                isFiltered = false;
              }
            } else if (key === 'uspCode' && data.forms) {
              if (!data.uspCode.split(', ').includes(filter[key])) {
                isFiltered = false;
              }
            } else if (key === 'query') {
              if (materialNumber1Regex.exec(query) !== null) {
                if (!data.materialNumbers.split(',').includes(query)) {
                  isFiltered = false;
                }
              } else {
                const searchTerms = query.split(' ');
                if (!this.filterBySearchTerms(searchTerms)) {
                  isFiltered = false;
                }
              }
            } else if (filter[key] !== data[key]) {
              isFiltered = false;
            }
          }
        }
      }
    }

    return isFiltered;
  }

  show() {
    const {
      element,
      isImgLoaded,
      imgElement,
    } = this;
    element.hidden = false;
    if (element.parentElement.nodeName === 'LI') {
      element.parentElement.hidden = false;
    }
    if (!isImgLoaded && imgElement) {
      imgElement.setAttribute('src', imgElement.dataset.src);
      this.isImgLoaded = true;
    }
  }

  hide() {
    const {
      element,
    } = this;
    element.hidden = true;
    if (element.parentElement.nodeName === 'LI') {
      element.parentElement.hidden = true;
    }
  }
}

export default MaterialThumb;
