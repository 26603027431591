class MaterialTableItem {
  constructor(element) {
    const aElement = element.querySelector('a');
    element.classList.add('-js');

    element.addEventListener('click', () => {
      window.location.href = aElement.href;
    });
  }
}

export default MaterialTableItem;
