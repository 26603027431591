/* eslint-disable no-param-reassign */
[...document.querySelectorAll('a[href^="geo:"]')].forEach((geoLinkElement) => {
  const isApple = /Mac|iPad|iPhone|iPod/.test(navigator.userAgent);
  const { href } = geoLinkElement;
  const indexOfQuestionMark = href.indexOf('?') === -1 ? undefined : href.indexOf('?');
  const latLon = href.substring(4, indexOfQuestionMark);
  let options = indexOfQuestionMark ? href.substring(indexOfQuestionMark + 1).split('&') : [];
  options = options.map((item) => ({
    key: item.substring(0, item.indexOf('=')),
    value: item.substring(item.indexOf('=') + 1),
  }));
  if (options.findIndex((item) => item.key === 'z') === -1) {
    options.push({
      key: 'z',
      value: 18,
    });
  }
  if (!isApple) {
    options = options.map((item) => {
      if (item.key === 'z') {
        return {
          key: 'zoom',
          value: item.value,
        };
      }
      if (item.key === 'q') {
        return {
          key: 'query',
          value: item.value,
        };
      }
      return item;
    });
  }
  const additionalParameters = options.map((item) => `${item.key}=${decodeURIComponent(item.value)}`).join('&');
  if (isApple) {
    geoLinkElement.href = `https://maps.apple.com/?ll=${latLon}&${additionalParameters}`;
    geoLinkElement.textContent = geoLinkElement.textContent.replace(/{{Maps App}}/gi, 'Apple Maps');
  } else {
    geoLinkElement.href = `https://www.google.com/maps/@?api=1&map_action=map&center=${latLon}&${additionalParameters}`;
    geoLinkElement.textContent = geoLinkElement.textContent.replace(/{{Maps App}}/gi, 'Google Maps');
  }
});
