import Reference from './Reference';

class References {
  constructor(element) {
    const filterElementId = element.dataset.filterId;
    const filterElement = document.getElementById(filterElementId);
    const referenceElements = element.querySelectorAll('.reference');
    const listElement = element.querySelector('.references__list');
    const filterInputElements = filterElement.querySelectorAll('.filter__label select');
    const moreButtonElement = element.querySelector('.references__more-button');
    const buttonShowMoreElement = element.querySelector('.button-show-more');

    this.references = [...referenceElements].map((referenceElement) => new Reference(referenceElement));
    this.element = element;
    this.listElement = listElement;
    this.moreButtonElement = moreButtonElement;
    this.noResultsForFilterElement = element.querySelector('.references__no-results-for-filter');

    buttonShowMoreElement.addEventListener('click', () => {
      this.expand();
    });
  }

  updateReferences(productVariant) {
    const {
      references,
      listElement,
      noResultsForFilterElement,
    } = this;
    const filter = { ...productVariant };
    delete filter.articleNumber;
    delete filter.form;
    const visibleReferences = [];
    references.forEach((reference) => {
      if (reference.filter(filter)) {
        reference.show();
        visibleReferences.push(reference);
      } else {
        reference.hide(true);
      }
    });
    if (visibleReferences.length > 0) {
      listElement.hidden = false;
      noResultsForFilterElement.hidden = true;
    } else {
      listElement.hidden = true;
      noResultsForFilterElement.hidden = false;
    }
    this.checkForButtonShowMore();
  }

  checkForButtonShowMore() {
    const {
      element,
      listElement,
      moreButtonElement,
    } = this;
    if (listElement.offsetHeight <= element.offsetHeight) {
      moreButtonElement.hidden = true;
    } else {
      moreButtonElement.hidden = false;
    }
  }

  expand() {
    const {
      element,
    } = this;

    element.classList.add('-expanded');
  }
}

export default References;
