class MainnavSection {
  constructor(element, buttonElement, mainnav) {
    const mainnavSection = this;
    this.element = element;
    this.buttonElement = buttonElement;
    this.mainnav = mainnav;
    this.depth = parseInt(element.dataset.depth, 10);

    function onButtonClick() {
      const mainnavContainerElement = buttonElement.closest('div');
      if (mainnavContainerElement) {
        const openElement = mainnavContainerElement.querySelector('a.-open');
        if (openElement) {
          openElement.classList.remove('-open');
        }
      }

      if (element.hidden) {
        mainnavSection.open();
      } else {
        mainnavSection.close();
      }
    }

    element.addEventListener('transitionend', () => {
      if (element.classList.contains('-hidden')) {
        element.hidden = true;
      } else {
        // const firstElement = element.querySelector('a, button');
        // if (firstElement) {
        //   firstElement.focus();
        // }
        this.mainnav.element.scroll({
          left: element.offsetLeft,
          behavior: 'smooth',
        });
        // this.mainnav.element.scroll
        // element.scrollIntoView({
        //   behavior: 'smooth',
        // });
      }
    });
    buttonElement.addEventListener('click', onButtonClick);
  }

  open() {
    const {
      element,
      mainnav,
      buttonElement,
      depth,
    } = this;
    mainnav.sections.forEach((section) => {
      if (section !== this && section.depth === depth && !section.element.hidden) {
        section.close();
      }
    });
    element.hidden = false;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        element.focus();
        element.classList.remove('-hidden');
      });
    });
    buttonElement.classList.add('-open');
  }

  close() {
    const {
      element,
      buttonElement,
      mainnav,
      depth,
    } = this;
    element.classList.add('-hidden');
    buttonElement.classList.remove('-open');
    mainnav.sections.forEach((section) => {
      if (depth === 1 && section.depth === 2) {
        section.close();
      }
    });
  }
}

export default MainnavSection;
