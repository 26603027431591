import Element from './Element';

class PageThumb extends Element {
  constructor(data) {
    const {
      url,
      title,
      breadcrumbs,
      metaDescription,
    } = data;
    const element = document.createElement('a');
    element.innerHTML = `
    <em>${breadcrumbs.join(' / ')}</em>
    <strong>${title}</strong>
    <p>${metaDescription}</p>
    `;
    element.classList.add('page-thumb');
    element.setAttribute('href', url);
    // element.hidden = true;

    super(element);

    this.data = data;
    this.element = element;
  }

  filter(searchTerm) {
    if (searchTerm) {
      const {
        title,
      } = this.data;
      const lowerSearchTerm = searchTerm.toLowerCase();
      return title.toLowerCase().includes(lowerSearchTerm);
    }
    return false;
  }
}

export default PageThumb;
