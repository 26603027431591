import FilterLabel from './FilterLabel';
import Popup from './Popup';
import Element from './Element';
import MaterialImages from './MaterialImages';
import References from './References';
import countries from './countries';

class Material {
  constructor(element) {
    const material = this;
    const filterElement = element.querySelector('.filter');
    const filterLabelElements = filterElement.querySelectorAll('.filter__label');
    const selectFormElement = filterElement.querySelector('select[name="form"]');
    const articleNumberElement = element.querySelector('.article-number');
    const materialImagesElement = element.querySelector('.material-images');
    const articleNumberStrongElement = articleNumberElement.querySelector('strong');
    const requestProductPopupElement = document.getElementById('request-product');
    const guardsElement = document.getElementById('guards');
    const guards = JSON.parse(guardsElement.innerHTML);
    const referencesElement = element.querySelector('.references');

    this.element = element;
    this.requestProductButton = filterElement.querySelector('.button');
    this.requestProductPopup = new Popup(requestProductPopupElement);
    this.articleNumbersElementInstance = new Element(articleNumberElement);
    this.materialImages = new MaterialImages(materialImagesElement);
    this.guards = guards;
    this.filterLabels = [];
    this.productVariant = {};
    this.hashArray = [];
    this.form = '';
    this.materialNumber = null;
    this.partNumberForm = null;
    this.partNumberLength = null;
    this.partNumberLengthGuard = null;
    this.partNumberInnerDiameter = null;
    this.partNumberWeight = null;
    this.articleNumber = null;
    if (referencesElement) {
      this.references = new References(referencesElement);
    }

    function showHideLabels() {
      const {
        filterLabels,
      } = material;
      const form = selectFormElement.value;
      if (form === 'bulk') {
        filterLabels.forEach((filterLabel) => {
          const { name } = filterLabel;
          if (name === 'length' || name === 'innerDiameter' || name === 'lengthGuard') {
            filterLabel.hide();
          } else {
            filterLabel.show();
          }
        });
      } else if (form === 'guard') {
        filterLabels.forEach((filterLabel) => {
          const { name } = filterLabel;
          if (name === 'weight' || name === 'length') {
            filterLabel.hide();
          } else {
            filterLabel.show();
          }
        });
      } else {
        filterLabels.forEach((filterLabel) => {
          const { name } = filterLabel;
          if (name === 'weight' || name === 'lengthGuard') {
            filterLabel.hide();
          } else {
            filterLabel.show();
          }
        });
      }
    }

    function updateArticleNumber() {
      const {
        materialNumber,
        partNumberForm,
        partNumberLength,
        partNumberLengthGuard,
        partNumberInnerDiameter,
        partNumberWeight,
        articleNumbersElementInstance,
      } = material;
      let articleNumber = '';
      if (materialNumber !== null) {
        if (partNumberForm === 's' && partNumberLength !== null && partNumberInnerDiameter !== null) {
          // column
          articleNumber = `${materialNumber}${partNumberForm}${partNumberLength}${partNumberInnerDiameter}`;
        } else if (partNumberForm === 'v' && partNumberLengthGuard !== null && partNumberInnerDiameter !== null) {
          // guard
          articleNumber = `${materialNumber}${partNumberForm}${partNumberLengthGuard}${partNumberInnerDiameter}`;
        } else if (partNumberForm === '' && partNumberWeight !== null) {
          // bulk
          articleNumber = `${materialNumber}${partNumberForm}${partNumberWeight}`;
        } else {
          articleNumber = '';
        }
      }
      articleNumberStrongElement.innerText = articleNumber;
      material.productVariant.articleNumber = articleNumber;

      if (articleNumber.length > 0) {
        articleNumbersElementInstance.show();
      } else {
        articleNumbersElementInstance.hide();
      }
    }

    function updateLabel(labelElement) {
      const {
        productVariant,
      } = material;
      const selectedOption = labelElement.options[labelElement.selectedIndex];
      if (labelElement.name === 'particleSize') {
        productVariant.particleSize = labelElement.value.length > 0 ? labelElement.value : null;
        if (selectedOption.dataset.materialNumber) {
          material.materialNumber = selectedOption.dataset.materialNumber;
        } else {
          material.materialNumber = null;
        }
      } else if (labelElement.name === 'form') {
        productVariant.form = labelElement.value.length > 0 ? labelElement.value : null;
        if (selectedOption.dataset.partNumber !== undefined) {
          material.partNumberForm = selectedOption.dataset.partNumber;
        } else {
          material.partNumberLength = null;
        }
        showHideLabels();
        material.materialImages.show(productVariant.form);
      } else if (labelElement.name === 'length') {
        productVariant.length = labelElement.value.length > 0 ? labelElement.value : null;
        if (selectedOption.dataset.partNumber !== undefined) {
          material.partNumberLength = selectedOption.dataset.partNumber;
        } else {
          material.partNumberLength = null;
        }
      } else if (labelElement.name === 'lengthGuard') {
        productVariant.lengthGuard = labelElement.value.length > 0 ? labelElement.value : null;
        if (selectedOption.dataset.partNumber !== undefined) {
          material.partNumberLengthGuard = selectedOption.dataset.partNumber;
        } else {
          material.partNumberLengthGuard = null;
        }
      } else if (labelElement.name === 'innerDiameter') {
        productVariant.innerDiameter = labelElement.value.length > 0 ? labelElement.value : null;
        if (selectedOption.dataset.partNumber !== undefined) {
          material.partNumberInnerDiameter = selectedOption.dataset.partNumber;
        } else {
          material.partNumberInnerDiameter = null;
        }
      } else if (labelElement.name === 'weight') {
        productVariant.weight = labelElement.value.length > 0 ? labelElement.value : null;
        if (selectedOption.dataset.partNumber !== undefined) {
          material.partNumberWeight = selectedOption.dataset.partNumber;
        } else {
          material.partNumberWeight = null;
        }
      }
      updateArticleNumber();
    }

    function setSelectElementsBasesdOnMaterialNumber(value) {
      const {
        filterLabels,
        references,
        productVariant,
      } = material;

      const materialNumberParts = value[1].split('.');
      if (materialNumberParts.length === 3) {
        // set variables
        const materialNumber = `${materialNumberParts[0]}.${materialNumberParts[1]}.`;
        let partNumberForm = '';
        if (materialNumberParts[2].startsWith('s')) {
          partNumberForm = 's'; // column
        } else if (materialNumberParts[2].startsWith('v')) {
          partNumberForm = 'v'; // guard
        }
        let partNumberLength = null;
        let partNumberInnerDiameter = null;
        let partNumberLengthGuard = null;
        let partNumberWeight = null;
        if (materialNumberParts[2].startsWith('s') || materialNumberParts[2].startsWith('v')) {
          partNumberLength = materialNumberParts[2].substring(1, 3);
          partNumberInnerDiameter = materialNumberParts[2].substring(materialNumberParts[2].length - 2);
        }
        if (materialNumberParts[2].startsWith('s')) {
          partNumberLength = materialNumberParts[2].substring(1, 3);
        } else if (materialNumberParts[2].startsWith('v')) {
          partNumberLengthGuard = materialNumberParts[2].substring(1, 3);
        } else {
          partNumberWeight = materialNumberParts[2];
        }

        // set select elements
        const formFilterLabel = filterLabels.find((filterLabel) => filterLabel.name === 'form');
        if (formFilterLabel) {
          const { options } = formFilterLabel.selectElement;
          const optionElement = [...options].find((selectElement) => selectElement.dataset.partNumber === partNumberForm);
          if (optionElement) {
            formFilterLabel.selectElement.value = optionElement.value;
            formFilterLabel.updateStatus();
          }
        }

        const particleSizeFilterLabel = filterLabels.find((filterLabel) => filterLabel.name === 'particleSize');
        if (particleSizeFilterLabel) {
          const { options } = particleSizeFilterLabel.selectElement;
          const optionElement = [...options].find((selectElement) => selectElement.dataset.materialNumber === materialNumber);
          if (optionElement) {
            particleSizeFilterLabel.selectElement.value = optionElement.value;
            productVariant.particleSize = optionElement.value;
            particleSizeFilterLabel.updateStatus();
          }
        }

        material.partNumberForm = partNumberForm;
        material.materialNumber = materialNumber;

        if (partNumberForm === 's' || partNumberForm === 'v') { // column or guard
          material.partNumberInnerDiameter = partNumberInnerDiameter;
          const innerDiameterFilterLabel = filterLabels.find((filterLabel) => filterLabel.name === 'innerDiameter');
          if (innerDiameterFilterLabel) {
            const { options } = innerDiameterFilterLabel.selectElement;
            const optionElement = [...options].find((selectElement) => selectElement.dataset.partNumber === partNumberInnerDiameter);
            if (optionElement) {
              innerDiameterFilterLabel.selectElement.value = optionElement.value;
              productVariant.innerDiameter = optionElement.value;
              innerDiameterFilterLabel.updateStatus();
            }
          }
        }
        if (partNumberForm === 's') { // column
          material.partNumberLength = partNumberLength;

          const lengthFilterLabel = filterLabels.find((filterLabel) => filterLabel.name === 'length');
          if (lengthFilterLabel) {
            const { options } = lengthFilterLabel.selectElement;
            const optionElement = [...options].find((selectElement) => selectElement.dataset.partNumber === partNumberLength);
            if (optionElement) {
              lengthFilterLabel.selectElement.value = optionElement.value;
              productVariant.length = optionElement.value;
              lengthFilterLabel.updateStatus();
            }
          }
        } else if (partNumberForm === 'v') { // guard
          material.partNumberLengthGuard = partNumberLengthGuard;

          const lengthGuardFilterLabel = filterLabels.find((filterLabel) => filterLabel.name === 'lengthGuard');
          if (lengthGuardFilterLabel) {
            const { options } = lengthGuardFilterLabel.selectElement;
            const optionElement = [...options].find((selectElement) => selectElement.dataset.partNumber === partNumberLengthGuard);
            if (optionElement) {
              lengthGuardFilterLabel.selectElement.value = optionElement.value;
              productVariant.lengthGuard = optionElement.value;
              lengthGuardFilterLabel.updateStatus();
            }
          }
        } else if (partNumberForm === '') {
          material.partNumberWeight = partNumberWeight;

          const weightFilterLabel = filterLabels.find((filterLabel) => filterLabel.name === 'weight');
          if (weightFilterLabel) {
            const { options } = weightFilterLabel.selectElement;
            const optionElement = [...options].find((selectElement) => selectElement.dataset.partNumber === partNumberWeight);
            if (optionElement) {
              weightFilterLabel.selectElement.value = optionElement.value;
              productVariant.weight = optionElement.value;
              weightFilterLabel.updateStatus();
            }
          }
        }

        updateArticleNumber();
        showHideLabels();
        material.updateRequestProductPopup();
        if (references) {
          references.updateReferences(productVariant);
        }
      }
    }

    function updateLabelsBasedOnHash() {
      const {
        filterLabels,
        productVariant,
        references,
      } = material;
      // hash may be like this: "#!request-product,productVariant[form=column,particleSize=3]"
      const hash = window.location.hash.match(/productVariant\[(.*)\]/);
      if (hash && hash[1]) {
        const hashArray = hash[1].split(',');
        hashArray.forEach((item) => {
          const itemNameValue = item.split('=');
          if (itemNameValue[0] === 'materialNumber') {
            setSelectElementsBasesdOnMaterialNumber(itemNameValue);
          } else if (itemNameValue[0] && itemNameValue[1]) {
            const name = itemNameValue[0];
            const value = itemNameValue[1];
            filterLabels.forEach((label) => {
              if (label.name === name) {
                label.setValue(value);
                label.updateStatus();
                updateLabel(label.selectElement);
              }
            });
          }
        });
        // set form if it is not set by hasArray
        if (!productVariant.form) {
          const formSelectElement = filterLabels.find((label) => label.name === 'form').selectElement;
          updateLabel(formSelectElement);
        }
        if (references) {
          references.updateReferences(productVariant);
        }
      }
    }

    function onLabelChange(event) {
      const {
        createHashArray,
        updateHash,
        updateRequestProductPopup,
        productVariant,
        references,
      } = this;
      updateLabel(event.target);
      createHashArray.bind(this)();
      updateHash.bind(this)();
      updateRequestProductPopup.bind(this)();
      if (references) {
        references.updateReferences(productVariant);
      }
    }

    [...filterLabelElements].forEach((filterLabelElement) => {
      const filterLabel = new FilterLabel(filterLabelElement);
      this.filterLabels.push(filterLabel);
      filterLabel.selectElement.addEventListener('change', onLabelChange.bind(this));
    });

    this.requestProductButton.addEventListener('click', (event) => {
      if (this.requestProductButton.getAttribute('aria-disabled') === 'true') {
        event.preventDefault();
        alert('Please select Particle Size, Length and Inner Diameter');
      }
    });

    const hash = window.location.hash.match(/productVariant\[(.*)\]/);
    if (hash && hash[1]) {
      updateLabelsBasedOnHash();
    } else {
      [...this.filterLabels].forEach((filterLabel) => {
        updateLabel(filterLabel.selectElement);
      });
    }
    this.createHashArray();
    this.updateRequestProductPopup();
  }

  createHashArray() {
    const { productVariant } = this;
    const hashArray = [];
    const allowedKeys = ['form', 'particleSize', 'length', 'lengthGuard', 'innerDiameter', 'weight'];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in productVariant) {
      if (productVariant[key] && allowedKeys.includes(key)) {
        hashArray.push(`${key}=${productVariant[key]}`);
      }
    }
    this.hashArray = hashArray;
  }

  updateHash() {
    const { hashArray } = this;
    window.history.replaceState(null, document.title, `#!productVariant[${hashArray.toString()}]`);
  }

  resetFormErrors(formElement) {
    const { elements } = formElement;
    [...elements].forEach((itemElement) => {
      const fieldElement = itemElement.closest('.field');
      if (fieldElement) {
        fieldElement.querySelector('.field__error').innerText = '';
      }
    });
  }

  showFormErrors(formElement, errors) {
    const { elements } = formElement;
    Object.keys(errors).forEach((errorKey) => {
      if (elements[errorKey]) {
        elements[errorKey].closest('.field').querySelector('.field__error').innerText = errors[errorKey];
      } else {
        console.error(errorKey, errors[errorKey]);
      }
    });
  }

  showSuccess(formElement) {
    const successElement = Object.assign(document.createElement('div'), {
      className: 'form__success-text',
      innerHTML: `
        Thank you very much!<br>
        We have received your request. We will contact you shortly.
      `,
    });

    formElement.classList.add('-success');

    formElement.querySelector('.button').remove();
    formElement.appendChild(successElement);
  }

  updateRequestProductPopup() {
    const {
      element,
      requestProductButton,
      materialNumber,
      guards,
      hashArray,
    } = this;
    const {
      form,
      particleSize,
      length,
      lengthGuard,
      innerDiameter,
      weight,
      articleNumber,
    } = this.productVariant;

    const { containerElement } = this.requestProductPopup;

    const materialTitle = element.querySelector('h1').innerText;

    let tableRows = '';

    if (particleSize) {
      tableRows += `
        <tr>
          <th>Particle Size</th>
          <td>${particleSize} µm</td>
        </tr>`;
    }

    if (form === 'column') {
      tableRows += `
        <tr>
          <th>Form</th>
          <td>Prepacked Column</td>
        </tr>`;
      if (length) {
        tableRows += `
          <tr>
            <th>Length</th>
            <td>${length} mm</td>
          </tr>`;
      }
      if (innerDiameter) {
        tableRows += `
          <tr>
            <th>Inner Diameter</th>
            <td>${innerDiameter} mm</td>
          </tr>`;
      }
    } else if (form === 'guard') {
      tableRows += `
        <tr>
          <th>Form</th>
          <td>Guard</td>
        </tr>`;
      if (length) {
        tableRows += `
          <tr>
            <th>Length</th>
            <td>${lengthGuard} mm</td>
          </tr>`;
      }
      if (innerDiameter) {
        tableRows += `
          <tr>
            <th>Inner Diameter</th>
            <td>${innerDiameter} mm</td>
          </tr>`;
      }
    } else if (form === 'bulk' && weight) {
      tableRows += `
        <tr>
          <th>Form</th>
          <td>Bulk only</td>
        </tr>`;
      if (weight) {
        tableRows += `
          <tr>
            <th>Weight</th>
            <td>${weight} g</td>
          </tr>
        `;
      }
    }

    if (articleNumber) {
      tableRows += `
        <tr>
          <th>Article Number</th>
          <td>${articleNumber}</td>
        </tr>`;
    }

    let countriesSelectElement = '<select name="country" required autocomplete="country">';
    countriesSelectElement += '<option></option>';
    Object.keys(countries).forEach((key) => {
      countriesSelectElement += `<option value="${key}">${countries[key]}</option>`;
    });
    countriesSelectElement += '</select>';

    const productVariantData = this.productVariant;
    productVariantData.materialTitle = materialTitle;

    const formHTML = `
      <form class="form" method="post" action="/api/request-material">
        <h3>Personal Details</h3>
        <input type="hidden" name="product_variant" value='${JSON.stringify(productVariantData)}'>
        <label class="field">
          <span class="field__label">Name <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="name">
        </label>
        <div class="visually-hidden field">
          <span class="field__label">Honigtopf <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="url" name="honigtopf" tabindex="-1">
        </div>
        <label class="field">
          <span class="field__label">Company <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="company">
        </label>
        <label class="field">
          <span class="field__label">Position</span>
          <span class="field__error"></span>
          <select name="position">
            <option></option>
            <option value="R&D">R&D</option>
            <option value="Sales">Sales</option>
            <option value="other">other</option>
          </select>
        </label>
        <label class="field">
          <span class="field__label">Business Email Address <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="email" required name="email">
        </label>
        <label class="field">
          <span class="field__label">Phone Number</span>
          <span class="field__error"></span>
          <input type="tel" name="phone">
        </label>
        <label class="field">
          <span class="field__label">Street Address <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="street_address">
        </label>
        <label class="field -span-2">
          <span class="field__label">Postal Code / ZIP</span>
          <span class="field__error"></span>
          <input type="text" name="postal_code">
        </label>
        <label class="field -span-4">
          <span class="field__label">City <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          <input type="text" required name="city">
        </label>
        <label class="field">
          <span class="field__label">Country <abbr title="required">*</abbr></span>
          <span class="field__error"></span>
          ${countriesSelectElement}
        </label>
        <label class="field">
          <span class="field__label">Message</span>
          <span class="field__error"></span>
          <textarea name="message" rows="5"></textarea>
        </label>
        <button class="button">Send Request</button>
      </form>
    `;

    let guardsHTML = '';
    const suitableGuards = guards.filter((guard) => guard.innerDiameter.toString() === innerDiameter);

    if (form === 'column' && particleSize && innerDiameter && suitableGuards.length > 0) {
      let guardsList = '';
      let directHolderList = '';
      let standAloneHolderList = '';
      suitableGuards.forEach((guard) => {
        guardsList += `<li>${guard.length} × ${innerDiameter} mm <span class="article-number -small"><strong>${materialNumber}${guard.articleNumber}</strong></span></li>`;
        directHolderList += `<li>
          ${guard.length} mm <span class="article-number -small -guards"><strong>${guard.partNumberDirectHolder}</strong></span>
        </li>`;
        standAloneHolderList += `
        <li>
          ${guard.length} mm <span class="article-number -small -guards"><strong>${guard.partNumberStandAloneHolder}</strong></span>
        </li>`;
      });

      guardsHTML = `
        <hr>
        <h3>Suitable Guards and Holder</h3>
        <div class="text -grid-3-auto -margin-top-small">
          <div>
            <h4 class="text__h4">Guards</h4>
            <ul class="list-guards">
              ${guardsList}
            </ul>
          </div>
          <div>
            <h4 class="text__h4">Direct Holder</h4>
            <ul class="list-guards">
              ${directHolderList}
            </ul>
          </div>
          <div>
            <h4 class="text__h4">Stand Alone Holder</h4>
            <ul class="list-guards">
              ${standAloneHolderList}
            </ul>
          </div>
        </div>`;
    }

    containerElement.innerHTML = `
      <h2>Material Overview</h2>
      <table class="table -small">
        <tr>
          <th>Material</th>
          <td>${materialTitle}</td>
        </tr>
        ${tableRows}
      </table>
      ${formHTML}
      ${guardsHTML}`;

    containerElement.querySelector('form').addEventListener('submit', (event) => {
      event.preventDefault();

      const formElement = event.target;
      const formData = new FormData(formElement);
      formElement.classList.add('-loading');
      this.resetFormErrors(formElement);

      // Disable form elements
      Array.from(formElement).forEach((itemElement) => {
        Object.assign(itemElement, {
          disabled: true,
        });
      });

      fetch(formElement.action, {
        method: formElement.method,
        body: formData,
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong');
      }).then((data) => {
        if (data.success === true) {
          this.showSuccess(formElement);
        } else if (data.errors) {
          this.showFormErrors(formElement, data.errors);
        }
      }).catch((error) => console.error(error))
        .finally(() => {
          formElement.classList.remove('-loading');
          if (!formElement.classList.contains('-success')) {
            // Enable form elements
            Array.from(formElement).forEach((itemElement) => {
              Object.assign(itemElement, {
                disabled: false,
              });
            });
          }
        });
    });

    if (articleNumber) {
      requestProductButton.setAttribute('aria-disabled', 'false');
      requestProductButton.removeAttribute('title');
      requestProductButton.href = `#!request-product,productVariant[${hashArray.toString()}]`;
    } else {
      requestProductButton.setAttribute('aria-disabled', 'true');
      requestProductButton.setAttribute('title', 'Please select Particle Size, Length and Inner Diameter');
      requestProductButton.href = '#!';
    }
  }
}

const element = document.querySelector('.material');
if (element) {
  // eslint-disable-next-line no-underscore-dangle
  document._material = new Material(element);
}
