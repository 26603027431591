import Search from './Search';
import Mainnav from './Mainnav';

class Header {
  constructor(element) {
    const buttonElement = element.querySelector('button');
    const menuSearchElement = element.querySelector('.header__menu-search');
    const bodyElement = document.querySelector('body');
    const searchElement = document.querySelector('.search');
    const mainnavElement = document.querySelector('.mainnav');

    this.element = element;
    this.buttonElement = buttonElement;
    this.menuSearchElement = menuSearchElement;
    this.bodyElement = bodyElement;
    this.mainnav = new Mainnav(mainnavElement);
    this.search = new Search(searchElement, this.mainnav, this);

    const onKeydown = (event) => {
      if (event.keyCode === 27) {
        if (element.classList.contains('-open')) {
          if (this.search.open === true) {
            this.search.hide();
          } else {
            this.closeMenuSearch();
            buttonElement.focus();
          }
        }
      }
    };

    buttonElement.addEventListener('click', () => {
      if (menuSearchElement.hidden) {
        this.openMenuSearch();
      } else if (this.search.open === true) {
        this.search.hide();
      } else {
        this.closeMenuSearch();
      }
    });

    document.addEventListener('keydown', onKeydown.bind(this));

    function onTransitionend(event) {
      if (event.target === this.menuSearchElement) {
        this.menuSearchElement.hidden = menuSearchElement.classList.contains('-hidden');
        this.search.hide(true);
      }
    }

    menuSearchElement.addEventListener('transitionend', onTransitionend.bind(this));

    // Sonderspezialfall:
    // Wenn von einer Produkt-Seite aus gesucht wird und ein das gleiche Produkt angeklickt wird, passiert ein hashchange und die Mainnav muss ausgeblendet werden.
    window.addEventListener('hashchange', () => {
      this.closeMenuSearch();
    });
  }

  openMenuSearch() {
    const {
      element,
      buttonElement,
      menuSearchElement,
      bodyElement,
    } = this;
    if (menuSearchElement.hidden) {
      menuSearchElement.hidden = false;
      element.classList.add('-open');
      bodyElement.classList.add('-no-scroll');
      buttonElement.classList.add('-active');
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          menuSearchElement.classList.remove('-hidden');
        });
      });
    }
  }

  closeMenuSearch() {
    const {
      element,
      buttonElement,
      menuSearchElement,
      bodyElement,
    } = this;
    if (!menuSearchElement.hidden) {
      element.classList.remove('-open');
      bodyElement.classList.remove('-no-scroll');
      buttonElement.classList.remove('-active');
      menuSearchElement.classList.add('-hidden');
    }
  }
}

const headerElement = document.querySelector('.header');
if (headerElement) {
  // eslint-disable-next-line no-underscore-dangle
  document._header = new Header(headerElement);
}
